<template>
  <hcc-dropdown custom customClass="custom-position">
    <template #button>
      <hcc-button-icon
        v-tooltip="$t('app.language')"
        :muted="false"
        color="accent"
      >
        <translate-icon class="translate-icon" />
      </hcc-button-icon>
    </template>
    <hcc-dropdown-item
      @click="changeLanguage('es')"
      :selected="$moment.locale() === 'es'"
    >
      <span>{{ $t("app.spanish") }}</span>
    </hcc-dropdown-item>
    <hcc-dropdown-item
      @click="changeLanguage('en')"
      :selected="$moment.locale() === 'en'"
    >
      <span>{{ $t("app.english") }}</span>
    </hcc-dropdown-item>
  </hcc-dropdown>
</template>

<script>
import { mapMutations } from 'vuex';
import { LOCALE_LAGUAGE_CHANGE } from '@/eventTypes';
import EventBus from '@/eventBus';
import HccDropdown from '@/components/shared/HccDropdown/index.vue';
import HccDropdownItem from '@/components/shared/HccDropdown/DropdownItem.vue';
import errorHandler from '@/utils/error.handler';
import { setI18nLanguage } from '../i18n';


export default {
  components: {
    HccButtonIcon: () => import('@/components/shared/HccButtonIcon/index.vue'),
    HccDropdown,
    HccDropdownItem,
  },
  methods: {
    ...mapMutations('shared', ['setLang']),
    changeLanguage(lang) {
      setI18nLanguage(lang);
      this.$moment.locale(lang);
      this.setLang(lang);
      EventBus.$emit(LOCALE_LAGUAGE_CHANGE, lang);

      errorHandler.logErrors(this.$moment.locale());
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .custom-position {
  &.custom {
    &.bottom-left {
      top: 5px;
      left: -75px;
    }
  }
}
</style>
